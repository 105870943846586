import resume from '../assets/pdf/Aishwarya-resume.pdf'
import profile_picture from '../assets/png/profile.jpeg'

export const headerData = {
    name: 'Aishwarya Agarwal',
    title: "Data Scientist",
    desciption:"Gradute student at University at Buffalo pursuing Masters in Data Science. Gradute student at University at Buffalo pursuing Masters in Data Science Gradute student at University at Buffalo pursuing Masters in Data Science",
    image: profile_picture,
    resumePdf: resume
}
